import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { defer, from, Observable } from 'rxjs';
import { delay, map, takeUntil } from 'rxjs/operators';
import { AuthService } from '../services/auth.service';

@Injectable()
export class AuthInterceptor implements HttpInterceptor {
  private _dateFormat = /^\d{4}-\d{2}-\d{2}T\d{2}:\d{2}:\d{2}(.\d*)?[+-]\d{2}:\d{2}/;

  constructor(private authService: AuthService) { }

  public intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    // add token
    // Access authenticationService.user directly instead of using .onLogin.subscribe to retrieve
    // the token, because order of .subscribe calls is not guaranteed, which can lead to requests
    // being performed before the token is set here which in turn leads to HTTP 401.
    return defer(async () => {
        const isAuthenticated = await this.authService.isAuthenticated();

        if (isAuthenticated) {
            req = req.clone({ headers: req.headers.set('Authorization', 'Bearer ' + this.authService.accessToken) });
        }

        req = req.clone({ headers: req.headers.set('Accept', 'application/json') });
        return await next.handle(req)
          .pipe(
            // PATCH times out after 4.5s
            takeUntil(from([true]).pipe(delay(req.method === 'PATCH' ? 4500 : 30000))),
            // Convert to Date objects if a Date string is returned
            map((val: HttpEvent<any>) => {
              if (val instanceof HttpResponse) {
                const body = val.body;
                this.convert(body);
              }
              return val;
            })
          ).toPromise();
    });
  }

  private convert(body: any) {
    if (body === null || body === undefined ) {
      return body;
    }
    if (typeof body !== 'object' ) {
      return body;
    }
    for (const key of Object.keys(body)) {
      const value = body[key];
      if (this.isDateString(value)) {
        body[key] = new Date(value);
      } else if (typeof value === 'object') {
        this.convert(value);
      }
    }
  }

  private isDateString(value: any): boolean {
    if (value === null || value === undefined) {
      return false;
    }
    if (typeof value === 'string') {
      return this._dateFormat.test(value);
    }    return false;
  }
}
