<div class="d-flex flex-column flex-grow-1">
  <router-outlet></router-outlet>
</div>
<!-- Footer -->
<div class="row footer">
  <div class="col">
    <div class="footerwrapper">
      <p>© 2020 - {{year}} Spijker Magazine — <a (click)="openContactPopup()" style="font-weight: 600;">Contacteer ons</a></p>
      <p>Site made with ❤️ by <a class="delacom" href="https://www.delacomdevelopment.be" target="_blank">Delacom Development</a></p>
    </div>
  </div>
</div>
<app-contact-popup></app-contact-popup>
