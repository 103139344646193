import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { Observable } from 'rxjs';
import { Article } from '../model';

@Injectable()
export class ArticleRepository {
  constructor(private httpClient: HttpClient, private translateService: TranslateService) {
  }

  public getArticles(skip: number, take: number): Observable<Article[]> {
    return this.httpClient.get<Article[]>(`/api/article?skip=${skip}&take=${take}`);
  }

  public getArticle(id: string): Observable<Article> {
    return this.httpClient.get<Article>(`/api/article/${id}`);
  }

  public create(): Observable<Article> {
    const dto: Article = {
      title: this.translateService.instant('Admin.Article.NewArticle'),
      shortTitle: ''
    };
    return this.httpClient.post<Article>(`/api/article`, dto);
  }

  public patch(article: Article): Observable<Article> {
    return this.httpClient.patch<Article>(`/api/article`, article);
  }
}
