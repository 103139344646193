import { Injectable, Inject } from '@angular/core';

export interface IAppSettings {
    AppUrl: string;
    Identity: {
        AuthorityUrl: string;
    };
    PdfViewerApiKey: string;
}

@Injectable()
export class AppSettings implements IAppSettings {
    constructor(@Inject('windowObject') window: Window) {
        // Window["SpijkerAppSettings"] was assigned before Angular was bootstrapped
        Object.assign(this, window['SpijkerAppSettings']);
        // Now remove this info from the window
        window['SpijkerAppSettings'] = undefined;
    }

    public AppUrl: string;
    public Identity:
    {
        AuthorityUrl: string;
    };
    public PdfViewerApiKey: string;
}
