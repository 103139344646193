import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

@Injectable()
export class MailingRepository {
  constructor(private httpClient: HttpClient) {
  }

  public contact(email: string, firstName: string, lastName: string, subject: string, message: string): Observable<void> {
    return this.httpClient.post<void>(`/api/mailing/contact`, { email, firstName, lastName, subject, message });
  }

  public subscribe(email: string, firstName: string, lastName: string): Observable<void> {
    return this.httpClient.post<void>(`/api/mailing/subscription`, { email, firstName, lastName });
  }

  public unsubscribe(id: string): Observable<void> {
    return this.httpClient.delete<void>(`/api/mailing/${id}`);
  }

  public validate(id: string): Observable<void> {
    return this.httpClient.get<void>(`/api/mailing/validate/${id}`);
  }

  public sendNewMagazineMail(magazineId: string, subject: string, preheader: string): Observable<void> {
    return this.httpClient.post<void>(`/api/mailing/newmagazine`, { magazineId, subject, preheader });
  }
}
