import { EventEmitter, Injectable } from '@angular/core';

@Injectable()
export class ContactService {

  public openContactPopup: EventEmitter<void> = new EventEmitter<void>();

  constructor() {
  }

  public openContact() {
    this.openContactPopup.emit();
  }
}
