import { Component, Inject, OnDestroy, ViewChild } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { Subscription } from 'rxjs';
import { ContactPopupComponent } from './modules/site/components/shared/contact-popup/contact-popup.component';
import { ContactService } from './services/contact.service';
import { OverflowService } from './services/overflow.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html'
})
export class AppComponent implements OnDestroy {
  public title = 'Spijker Magazine';
  public year = (new Date()).getFullYear();

  @ViewChild(ContactPopupComponent)
  public contactPopup: ContactPopupComponent;

  public disableOverflow = false;

  private subscription: Subscription;
  private querySubscription: Subscription;

  constructor(
    private translateService: TranslateService,
    private contactService: ContactService,
    private route: ActivatedRoute,
    private overflowService: OverflowService,
    @Inject('documentObject') private document: Document) {
    this.translateService.setDefaultLang('nl');
    this.translateService.use('nl');
    this.querySubscription = this.route.queryParams
      .subscribe((p) => {
        if (p['contact'] === 'true') {
          this.openContactPopup();
        }
      });
    this.subscription = this.contactService.openContactPopup.subscribe(() => {
      this.openContactPopup();
    });
    this.overflowService.allowOverflowChange.subscribe((allow) => {
      this.document.body.style.overflowY = allow ? 'auto' : 'hidden';
    });
  }

  public openContactPopup() {
    this.contactPopup.show();
  }

  public ngOnDestroy() {
    if (this.subscription) {
      this.subscription.unsubscribe();
    }
    if (this.querySubscription) {
      this.querySubscription.unsubscribe();
    }
  }
}
