import { LOCATION_INITIALIZED } from '@angular/common';
import { HttpClient, HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { APP_INITIALIZER, Injector, NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { TranslateLoader, TranslateModule, TranslateService } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { ShareService } from 'ngx-sharebuttons';
import { ShareButtonsConfig, ShareModule } from 'ngx-sharebuttons';
import { UserManager } from 'oidc-client';
import { AppComponent } from './app.component';
import { AppRoutingModule } from './app.routing.module';
import { AuthGuard } from './guards/auth.guard';
import { CoreModule } from './modules/core/core.module';
import { ContactPopupComponent } from './modules/site/components/shared/contact-popup/contact-popup.component';
import { AuthResolver } from './resolvers/auth.resolver';
import { AuthService } from './services/auth.service';
import { ContactService } from './services/contact.service';
import { AppSettings } from './utils/appsettings';
import { AuthInterceptor } from './utils/auth.interceptor';
import { library } from '@fortawesome/fontawesome-svg-core';
import { faSave } from '@fortawesome/free-solid-svg-icons';

export function HttpLoaderFactory(http: HttpClient) {
  return new TranslateHttpLoader(http);
}

const customConfig: ShareButtonsConfig = {
  include: ['facebook', 'twitter', 'whatsapp', 'messenger', 'linkedin'],
  gaTracking: true,
  theme: 'circles-dark'
};

@NgModule({
  imports: [
    BrowserModule.withServerTransition({ appId: 'ng-cli-universal' }),
    BrowserAnimationsModule,
    CoreModule,
    HttpClientModule,
    AppRoutingModule,
    TranslateModule.forRoot({
      defaultLanguage: 'nl',
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient]
      }
    }),
    ShareModule.withConfig(customConfig),
  ],
  declarations: [
    AppComponent,
    ContactPopupComponent
  ],
  providers: [
    {
      provide: APP_INITIALIZER,
      useFactory: appInitializerFactory,
      deps: [TranslateService, Injector],
      multi: true
    },
    {
      provide: 'windowObject',
      useValue: window
    },
    {
      provide: 'documentObject',
      useValue: document
    },
    {
      provide: UserManager,
      useFactory: openIdConnectFactory,
      deps: [AppSettings]
    },
    AppSettings,
    AuthService,
    AuthGuard,
    {
      // Custom interceptor for catching all http requests
      provide: HTTP_INTERCEPTORS,
      useClass: AuthInterceptor,
      multi: true,
      deps: [AuthService]
    },
    AuthResolver,
    ContactService,
    ShareService
  ],
  bootstrap: [AppComponent]
})
export class AppModule { 
    constructor() {
        library.add(faSave);
    }
}

export function appInitializerFactory(translate: TranslateService, injector: Injector) {
  return () => new Promise<any>((resolve: any) => {
    const locationInitialized = injector.get(LOCATION_INITIALIZED, Promise.resolve(null));
    locationInitialized.then(() => {
      const langToSet = 'nl';
      translate.setDefaultLang('nl');
      translate.use(langToSet).subscribe(() => {
      }, err => {
        console.error(`Problem with '${langToSet}' language initialization.'`);
      }, () => {
        resolve(null);
      });
    });
  });
}

export function openIdConnectFactory(settings: AppSettings): UserManager {
  const config: any = {
    authority: settings.Identity.AuthorityUrl,
    client_id: 'Spijker',
    redirect_uri: settings.AppUrl + 'admin',
    post_logout_redirect_uri: settings.AppUrl + 'admin',
    response_type: 'id_token token',
    scope: 'Spijker openid',
    silent_redirect_uri: settings.AppUrl + 'admin/auth.html',
    silentRequestTimeout: 3000,
    automaticSilentRenew: false,
    loadUserInfo: true,
    clockSkew: 60,
    filterProtocolClaims: true
  };
  return new UserManager(config);
}
