import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AuthGuard } from './guards/auth.guard';
import { AuthResolver } from './resolvers/auth.resolver';

const routes: Routes = [
  {
    path: 'admin',
    canActivate: [AuthGuard],
    loadChildren: () => import('./modules/admin/admin.module').then(m => m.AdminModule)
  },
  {
    path: '',
    resolve: [AuthResolver],
    loadChildren: () => import('./modules/site/site.module').then(m => m.SiteModule)
  },
  {
    path: '**',
    redirectTo: ''
  }
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes,
      {
    scrollPositionRestoration: 'enabled',
    relativeLinkResolution: 'legacy'
}),
    ],
  exports: [RouterModule]
})
export class AppRoutingModule { }
