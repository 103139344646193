import { Injectable } from '@angular/core';
import { Resolve } from '@angular/router';
import { AuthService } from '../services/auth.service';

@Injectable()
export class AuthResolver implements Resolve<boolean> {

  constructor(private authService: AuthService) { }

  // Always resolve, just make sure you're authenticated even when not necessary to load currently active page
  public resolve(): Promise<boolean> {
    return this.authService.doAuthenticationCheck(true).then(() => {
      return true;
    }, (err) => {
      console.log('Error while doing authentication check', err);
      return true;
    });
  }
}
