import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { Observable } from 'rxjs';
import { File as FileDto } from '../model';

@Injectable()
export class FileRepository {
  constructor(private httpClient: HttpClient, private translateService: TranslateService) {
  }

  public uploadFile(file: File): Observable<FileDto> {
    const data = new FormData();
    data.append('file', file);
    return this.httpClient.post<FileDto>(`/api/file`, data);
  }
}
