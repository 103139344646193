import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { ShareButtonsModule } from 'ngx-sharebuttons/buttons';
import { ShareIconsModule } from 'ngx-sharebuttons/icons';
import { ButtonModule } from 'primeng/button';
import { InputTextModule } from 'primeng/inputtext';
import { InputTextareaModule } from 'primeng/inputtextarea';
import { AssetUrlPipe } from 'src/app/pipes/asset-url.pipe';
import { SafeHtml } from 'src/app/pipes/safe-html.pip';
import { ArticleRepository } from 'src/app/repository/article.repository';
import { BlockRepository } from 'src/app/repository/block.repository';
import { FileRepository } from 'src/app/repository/file.repository';
import { MagazineRepository } from 'src/app/repository/magazine.repository';
import { MailingRepository } from 'src/app/repository/mailing.repository';
import { HeaderComponent } from '../site/components/shared/header/header.component';
import { ArticleDetailComponent } from './components/pages/article-detail/article-detail.component';
import { ModalComponent } from './components/widgets/modal/modal.component';



@NgModule({
  imports: [
    FontAwesomeModule,
    TranslateModule.forChild(),
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    ShareButtonsModule,
    ShareIconsModule,
    ButtonModule,
    InputTextModule,
    InputTextareaModule
  ],
  declarations: [
    HeaderComponent,
    ArticleDetailComponent,
    AssetUrlPipe,
    ModalComponent,
    SafeHtml
  ],
  exports: [
    FontAwesomeModule,
    TranslateModule,
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    ModalComponent,
    ShareButtonsModule,
    ShareIconsModule,
    InputTextModule,
    InputTextareaModule,
    ButtonModule,
    AssetUrlPipe,
    HeaderComponent,
    ArticleDetailComponent,
  ],
  providers: [
    ArticleRepository,
    MagazineRepository,
    TranslateService,
    BlockRepository,
    AssetUrlPipe,
    FileRepository,
    MailingRepository,
    SafeHtml
  ]
})
export class CoreModule { }
