import { Component, EventEmitter, Input, Output } from '@angular/core';
import { faTimes } from '@fortawesome/free-solid-svg-icons';

@Component({
  selector: 'app-modal',
  templateUrl: './modal.component.html'
})
export class ModalComponent {

  @Input()
  public showOverlay = true;

  @Input()
  public showClose = true;

  public visible = false;
  public overlayVisible = false;
  public modalVisible = false;

  public faClose = faTimes;

  @Output()
  public hidden: EventEmitter<void> = new EventEmitter<void>();

  constructor() {
  }

  public show() {
    this.visible = true;
    setTimeout(() => {
      this.overlayVisible = true;
      this.modalVisible = true;
    }, 100);
  }

  public hide() {
    this.modalVisible = false;
    this.overlayVisible = false;
    this.hidden.emit();
    setTimeout(() => {
      this.visible = false;
    }, 250);
  }
}
