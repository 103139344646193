import { Injectable } from '@angular/core';
import { CanActivate } from '@angular/router';
import { AuthService } from '../services/auth.service';

@Injectable()
export class AuthGuard implements CanActivate {

  constructor(private authService: AuthService) { }

  public canActivate(): Promise<boolean> {
    return this.authService.doAuthenticationCheck().then(() => {
      return this.authService.isAuthenticated();
    }, (err) => {
      console.log('Error while doing authentication check', err);
      return false;
    });
  }
}
