import { Component, ViewChild } from '@angular/core';
import { faExclamationTriangle, faPaperPlane } from '@fortawesome/free-solid-svg-icons';
import { ModalComponent } from 'src/app/modules/core/components/widgets/modal/modal.component';
import { MailingRepository } from 'src/app/repository/mailing.repository';

@Component({
  selector: 'app-contact-popup',
  templateUrl: './contact-popup.component.html'
})
export class ContactPopupComponent {

  public firstName: string;
  public lastName: string;
  public email: string;
  public subject: string;
  public message: string;
  public invalidEmail = false;

  public faPaperPlane = faPaperPlane;
  public faInvalid = faExclamationTriangle;

  @ViewChild(ModalComponent)
  public modal: ModalComponent;

  public get disableSend(): boolean {
    return !(!!this.firstName && !!this.lastName && !!this.email &&
      !!this.message && !!this.firstName.length && !!this.lastName.length &&
      !!this.email.length && !!this.message.length && !this.invalidEmail && !!this.subject && !!this.subject.length);
  }

  constructor(private mailingRepository: MailingRepository) {
  }

  public show() {
    this.modal.show();
  }

  public send() {
    this.mailingRepository.contact(this.email, this.firstName, this.lastName, this.subject, this.message).subscribe(() => {
      this.modal.hide();
    });
  }

  public validateMail() {
    if (/^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)+/.test(this.email)) {
      this.invalidEmail = false;
    } else {
      this.invalidEmail = true;
    }
  }

  public validateInput() {
    if (this.invalidEmail) {
      this.validateMail();
    }
  }

  public reset() {
    this.invalidEmail = false;
    this.firstName = '';
    this.lastName = '';
    this.email = '';
    this.subject = '';
    this.message = '';
  }
}
