import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
declare var $: any;

@Injectable({
  providedIn: 'root'
})
export class OverflowService {

  public allowOverflowChange: Observable<boolean>;
  private allowOverflowChangeSubject: BehaviorSubject<boolean>;

  constructor() {
    this.allowOverflowChangeSubject = new BehaviorSubject<boolean>(true);
    this.allowOverflowChange = this.allowOverflowChangeSubject.asObservable();
  }

  public allowOverflow() {
    this.allowOverflowChangeSubject.next(true);
  }

  public disableOverflow() {
    this.allowOverflowChangeSubject.next(false);
  }
}
