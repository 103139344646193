import { EventEmitter, Inject, Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { User, UserManager } from 'oidc-client';
import { from, fromEventPattern, Observable } from 'rxjs';
import { distinctUntilChanged, mergeMap } from 'rxjs/operators';

@Injectable()
export class AuthService {
  constructor(
    @Inject('windowObject') private window: Window,
    private userManager: UserManager,
    private router: Router) {
      this.setupSilentRenewal();
  }

  public onLogout: EventEmitter<void> = new EventEmitter<void>();
  public accessToken: string;

  private user: User;
  private tokenChanges: Observable<User>;

  public login(): Promise<any> {
    return this.userManager.signinRedirect({ state: this.window.location.pathname }).then(() => {
      // Sign in redirect successful!
    }).catch((error) => console.error('Sign in error: ' + error));
  }

  public logout(): void {
    this.userManager.signoutRedirect()
      .then(() => {
        this.onLogout.emit();
      })
      .catch((error) => console.error('Sign out error: ' + error));
  }

  public isAuthenticated(): boolean {
    return this.user && this.user.access_token && !this.user.expired;
  }

  // Background check can be used to authenticate if you are already logged in, but will take no action if you are not
  public async doAuthenticationCheck(backgroundCheck = false): Promise<void> {
    // If user is not loaded already, get from cache
    if (!this.user) {
      this.user = await this.userManager.getUser();
      if (this.isAuthenticated()) {
        // Trigger login success to get permissions
        this.user.state = this.window.location.pathname;
        return this.onLoginSuccess(this.user);
      }
    }
    if (this.window.location.hash && this.window.location.hash.indexOf('#id_token=') === 0) {
      // if so, then we are in a sign in callback from Identity
      return this.userManager.signinRedirectCallback()
        .then((user: User) => {
          return this.onLoginSuccess(user);
        })
        .catch((e) => {
          console.error(e.message);
          this.login();
        });
    } else {
      // check if there is a currently authenticated user
      if (!this.isAuthenticated() && !backgroundCheck) {
        return this.login();
      } else {
        // Already signed in
        return Promise.resolve();
      }
    }
  }

  private async onLoginSuccess(user: User, silent = false) {
    this.user = user;
    this.accessToken = user.access_token;
    if (!silent) {
      try {
        this.window.location.pathname = user.state || '/admin';
        this.window.location.hash = "";
      } catch (error) {
        this.logout();
        return;
      }
    }
  }

  private onLoginError(type: string, error: any) {
    throw new Error('AuthenticationManager > onLoginError: ' + type);
  }

  private setupSilentRenewal() {
    // create tokenChanges Observable
    this.tokenChanges = fromEventPattern(
      (handler) => { this.userManager.events.addAccessTokenExpiring(handler as any); },
      (handler) => { this.userManager.events.removeAccessTokenExpiring(handler as any); }
    ).pipe(
      mergeMap(() => from(this.userManager.signinSilent())),
      distinctUntilChanged()
    );

    // update user and token when changed
    this.tokenChanges.subscribe(
      (user) => this.onLoginSuccess(user, true),
      (error) => this.onLoginError('Silent renew', error)
    );
    this.userManager.events.addSilentRenewError(
      (error) => this.onLoginError('Silent renew', error)
    );
  }
}


