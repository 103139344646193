import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { Observable } from 'rxjs';
import { Magazine } from '../model/entities/magazine.model';

@Injectable()
export class MagazineRepository {
  constructor(private httpClient: HttpClient, private translateService: TranslateService) {
  }

  public getAllMagazines(skip: number, take: number): Observable<Magazine[]> {
    return this.httpClient.get<Magazine[]>(`/api/magazine?skip=${skip}&take=${take}`);
  }

  public getMagazine(id: string): Observable<Magazine> {
    return this.httpClient.get<Magazine>(`/api/magazine/${id}`);
  }

  public createMagazine(): Observable<Magazine> {
    return this.httpClient.post<Magazine>(`/api/magazine`, {
      title: this.translateService.instant('Admin.Magazine.NewMagazine')
    });
  }

  public updateMagazine(magazine: Magazine): Observable<Magazine> {
    return this.httpClient.patch<Magazine>(`/api/magazine`, magazine);
  }

  public deleteMagazine(id: string): Observable<void> {
    return this.httpClient.delete<void>(`/api/magazine/${id}`);
  }
}
