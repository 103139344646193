<app-modal (hidden)="reset()">
  <div class="d-flex flex-column">
    <h1 translate>Contact.Title</h1>
    <p translate>Contact.Content</p>
    <div class="d-flex flex-row my-3">
      <span class="p-float-label mx-auto">
        <input type="text" [(ngModel)]="firstName" pInputText>
        <label for="float-input" translate>Newsletter.FirstName</label>
      </span>
    </div>
    <div class="d-flex flex-row my-3">
      <span class="p-float-label mx-auto">
        <input type="text" [(ngModel)]="lastName" pInputText>
        <label for="float-input" translate>Newsletter.LastName</label>
      </span>
    </div>
    <div class="d-flex flex-row my-3">
      <div class="col">
        <div class="row">
          <span class="p-float-label mx-auto">
            <input type="text" [(ngModel)]="email" (blur)="validateMail()" (input)="validateInput()" pInputText>
            <label for="float-input" translate>Contact.Email</label>
          </span>
        </div>
        <div class="row mt-2" *ngIf="invalidEmail">
          <p class="error mx-auto my-0">
            <fa-icon class="mr-2" [icon]="faInvalid"></fa-icon>
            <span translate>Contact.InvalidEmail</span>
          </p>
        </div>
      </div>
    </div>
    <div class="d-flex flex-row my-3">
      <span class="p-float-label mx-auto">
        <input type="text" [(ngModel)]="subject" pInputText>
        <label for="float-input" translate>Contact.Subject</label>
      </span>
    </div>
    <div class="d-flex flex-row my-3">
      <span class="p-float-label mx-auto textinput">
        <textarea type="text" [(ngModel)]="message" pInputText></textarea>
        <label for="float-input" translate>Contact.Message</label>
      </span>
    </div>
    <p-button class="mx-auto p-button-rounded" (click)="send()" [disabled]="disableSend">
      <div class="mx-auto w-100">
        <fa-icon class="mr-2" [icon]="faPaperPlane"></fa-icon>
        <span translate>Contact.Send</span>
      </div>
    </p-button>
  </div>
</app-modal>
