import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { ArticleBlock } from '../model';

@Injectable()
export class BlockRepository {
  constructor(private httpClient: HttpClient) {
  }

  public getBlocks(articleId: string): Observable<ArticleBlock[]> {
    return this.httpClient.get<ArticleBlock[]>(`api/articleblock/${articleId}`);
  }

  public addBlock(block: ArticleBlock, articleId: string): Observable<ArticleBlock> {
    block.articleId = articleId;
    return this.httpClient.post<ArticleBlock>(`api/articleblock`, block);
  }

  public patchBlocks(blocks: ArticleBlock[]): Observable<ArticleBlock[]> {
    return this.httpClient.patch<ArticleBlock[]>(`api/articleblock`, blocks);
  }

  public deleteBlock(block: ArticleBlock): Observable<void> {
    return this.httpClient.delete<void>(`api/articleblock/${block.id}`);
  }
}
